import React from "react";
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';

import { Speedcurve } from "./src/components/third-party/speedcurve";
import { Eloqua } from "./src/components/third-party/eloqua";
import { GoogleTagManager } from './src/components/third-party/google-tagmanager';

// eslint-disable-next-line react/display-name, react/prop-types -- gatsby component
export default ({ element }) => {
	flagsmith.init({
		environmentID: process.env.GATSBY_FLAGSMITH_ENVIRONMENT_KEY,
		cacheFlags: true,
	});

	return (
		<FlagsmithProvider flagsmith={flagsmith}>
			{element}

			{process.env.NODE_ENV === 'production' && (
				<>
					<Speedcurve />
					<GoogleTagManager />
					<Eloqua />
				</>
			)}
		</FlagsmithProvider>
	);
};
