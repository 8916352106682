import React from 'react';
import { Script } from 'gatsby';

export const GoogleTagManager = () => (
	<>
		<Script key='google-tagmanager' id='google-tagmanager-script'>
			{`
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','GTM-K67LGG8');
			`}
		</Script>

		<noscript>
			<iframe title='Google Tag Manager' src='https://www.googletagmanager.com/ns.html?id=GTM-K67LGG8' height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} />
		</noscript>
	</>
);
