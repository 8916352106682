import React from 'react';
import { Script } from 'gatsby';

export const Eloqua = () => (
	<Script id='eloqua-script' key='eloqua'>
		{`
			var _elqQ = _elqQ || [];
			_elqQ.push(['elqSetSiteId', '1431711']);
			_elqQ.push(['elqUseFirstPartyCookie', 'tracking.reillyarch.com']);
			_elqQ.push(['elqTrackPageView']);

			(function() {
				function async_load() {
					var s = document.createElement('script'); s.type = 'text/javascript';
					s.async = true;
					s.src = '//img.en25.com/i/elqCfg.min.js';
					var x = document.getElementsByTagName('script')[0];
					x.parentNode.insertBefore(s, x);
				}
				if(window.addEventListener) window.addEventListener('DOMContentLoaded', async_load, false);
				else if (window.attachEvent) window.attachEvent('onload', async_load);
			})();
		`}
	</Script>
);
